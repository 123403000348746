
import Vue from "vue";
import { mask } from "vue-the-mask";
import store from "@/store";

export default Vue.extend({
  name: "Personal",

  directives: { mask },

  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({})
    },
    profile: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data: () => ({
    credentials: store.getters["authentication/credentials"],
    document: { title: "", document: null } as any,
    dialog: false as boolean,
    loading: false as boolean,
    errorMessages: {} as any,
    selects: {
      states: [] as Array<any>,
      genders: [
        {
          value: "m",
          text: "M"
        },
        {
          value: "f",
          text: "F"
        }
      ] as Array<any>,
      work_positions: [] as Array<any>,
      subdivisions: [] as Array<any>,
      branches: [] as Array<any>
    },
    model: {} as any,
    menu: false as boolean
  }),

  watch: {
    profile: {
      immediate: true,
      deep: true,
      handler() {
        this.model = this.profile;
      }
    }
  },

  methods: {
    save(date: string) {
      (this.$refs.menu as any).save(date);
    }
  }
});
