<script>
import { mask } from "vue-the-mask";
import rules from "@/services/helpers/validation-rules";

export default {
  name: "Security",

  directives: { mask },

  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data: () => ({
    rules,
    security: {},
    shownPassword: {
      new: false,
      confirm: false
    },
    errorMessages: {},
    selects: {
      doctors: []
    }
  }),

  computed: {
    computedRules() {
      return {
        minNumber: this.min
      };
    },
    samePassword() {
      return (
        this.security.password_confirmation === this.security.password ||
        `Пароли не совпадает!`
      );
    }
  },

  methods: {
    min(number) {
      return v => !v || Number(v) >= number || `Минимальное значение ${number}`;
    },
    async changeSecurity() {
      try {
        await this.$API.profile().changePassword(this.id, this.security);
        await this.$store.dispatch("alert/showSuccess", "Success");
        this.security = {
          ...this.security,
          password: undefined,
          old_password: undefined,
          password_confirmation: undefined
        };
        this.$refs.form.resetValidation();
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async changePassword() {
      const form = this.$refs.form;
      try {
        if (form.validate()) {
          await this.$API
            .profile()
            .changePassword(Number(this.user.id), this.security);
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("profile.alert.success")
          );
          this.security = {
            ...this.security,
            password: undefined,
            old_password: undefined,
            password_confirmation: undefined
          };
          this.$refs.form.resetValidation();
          document.location.href = "/";
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    getPasswordType(key) {
      if (this.shownPassword[key]) {
        return "text";
      }
      return "password";
    },
    togglePasswordType(key) {
      this.shownPassword[key] = !this.shownPassword[key];
    }
  }
};
</script>

<template>
  <v-card elevation="1" :class="{ 'mt-4': $vuetify.breakpoint.mdAndDown }">
    <v-card-title class="bordered">
      <span>
        {{ $t("profile.headers.security-title") }}
      </span>
    </v-card-title>
    <v-card-subtitle>
      {{ $t("profile.headers.security-subtitle") }}
    </v-card-subtitle>
    <v-card-text class="mt-3">
      <v-form ref="form" @submit.prevent.stop>
        <v-row>
          <v-col cols="12">
            <v-row class="mt-5">
              <v-col cols="12">
                <v-text-field
                  type="password"
                  :errorMessages="errorMessages.old_password"
                  v-model="security.old_password"
                  dense
                  :rules="[rules.required(), rules.min(8)]"
                  prepend-icon="mdi-lock"
                  :label="$t('auth.fields.password')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="security.password"
                  dense
                  :rules="[rules.required(), rules.min(8)]"
                  :errorMessages="errorMessages.password"
                  prepend-icon="mdi-lock-plus"
                  :label="$t('auth.fields.new_password')"
                  :type="getPasswordType('new')"
                >
                  <template #append>
                    <v-btn
                      @click="togglePasswordType('new')"
                      color="primary"
                      fab
                      x-small
                      text
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :errorMessages="errorMessages.password_confirmation"
                  v-model="security.password_confirmation"
                  dense
                  :rules="[rules.required(), ...samePassword]"
                  prepend-icon="mdi-lock-reset"
                  :label="$t('auth.fields.password_confirmation')"
                  :type="getPasswordType('confirm')"
                >
                  <template #append>
                    <v-btn
                      @click="togglePasswordType('confirm')"
                      color="primary"
                      fab
                      x-small
                      text
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex pt-0">
      <v-spacer></v-spacer>
      <v-btn @click="changePassword()" class="py-0" color="primary" depressed>
        {{ $t("global_buttons.save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
#pie-chart::v-deep .apexcharts-tooltip-series-group {
  background: rgba(0, 0, 0, 0.75) !important;
}
svg {
  fill: var(--v-primary-base);
}
.bordered {
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: var(--v-primary-base);
    content: " ";
    height: 30px;
    width: 2px;
    border-radius: 50px !important;
  }
}
.pie-list {
  li:not(:last-child) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}
.comments-container > div {
  &:hover {
    background: var(--v-greyish-base);
  }
  .status-container {
    .v-icon {
      &:after {
        opacity: 0;
      }
      &.edit-icon {
        &:hover {
          color: var(--v-primary-base);
        }
      }
      &.remove-icon {
        &:hover {
          color: var(--v-error-base);
        }
      }
    }
  }
}
.card-container::v-deep {
  .fb {
    background: #5158d8;
  }
  *:not(.v-btn span) {
    color: #fff;
  }
}
</style>
